// extracted by mini-css-extract-plugin
export var schedule = "_schedule-module--schedule--ITtjE";
export var schedule__tab = "_schedule-module--schedule__tab--TWKdJ";
export var schedule__tab_grey = "_schedule-module--schedule__tab_grey--MaONx";
export var schedule__tab_color = "_schedule-module--schedule__tab_color--CXXCk";
export var schedule__triangle = "_schedule-module--schedule__triangle--0ejRv";
export var schedule__year = "_schedule-module--schedule__year--m2Q1i";
export var schedule__year__list = "_schedule-module--schedule__year__list--YFKNA";
export var schedule__current_age = "_schedule-module--schedule__current_age--6L7UB";
export var schedule__bottom_text = "_schedule-module--schedule__bottom_text--bVWkk";
export var month_list = "_schedule-module--month_list--83l07";
export var event_list = "_schedule-module--event_list--UpXQb";
export var event_list__item = "_schedule-module--event_list__item--ecnIQ";
import * as React from 'react';
import { graphql } from 'gatsby';
import { dayjs } from '../lib/dayjs';
import { Layout } from '../components/layout';
import { Seo } from '../components/seo';
import { BreadCrumb } from '../components/breadcrumb';
import { BackGround } from '../components/background';
import * as Styles from '../styles/pages/_schedule.module.scss';
import * as CommonStyles from '../styles/global/_common.module.scss';
import { contentPathHelper } from '../utils/contentPathHelper'

import Flag from '/static/flag.svg';
import Cup from '/static/cup.svg';
import {Link, useIntl} from "gatsby-plugin-react-intl"

const EVENT_TYPE = {
  shingle: '0',
  exhibition: '1',
  tournament: '2',
};

// 数字の頭の0を削除する
const suppressZero = (str) => {
  let idx = 0;
  while (str.charAt(idx) === '0') idx++;
  return str.slice(idx);
};

const eventReordering = (data, years) => {
  const yearsSort = [];

  years.forEach((year) => {
    // 年ごとに要素を抽出して配列を作成
    const yearArray = data.filter(({ node }) => dayjs(node.in_date).format('YYYY') === year);

    // 配列から月の一覧を抽出
    const months = Array.from(new Set(yearArray.map(({ node }) => dayjs(node.in_date).format('MM'))));

    const monthSort = [];
    months.forEach((month) => {
      // 月ごとに要素を抽出して配列を作成
      const monthArray = yearArray.filter(({ node }) => dayjs(node.in_date).format('MM') === month);

      monthSort.push({
        month: suppressZero(month),
        items: monthArray,
      });
    });
    yearsSort.push({
      year: year,
      items: monthSort,
    });
  });
  return yearsSort;
};

const SchedulePage = ({ data, location }) => {
  const intl = useIntl()
  const t = (id) => intl.formatMessage({id})
  // 配列から年の一覧を抽出
  const years = Array.from(new Set(data.allMicrocmsEvent.edges.map(({ node }) => dayjs(node.in_date).format('YYYY'))));

  const [age, setAge] = React.useState(years[0]);

  const eventItems = eventReordering(data.allMicrocmsEvent.edges, years);

  return (
    <Layout location={location}>
      <Seo title={t('schedule.seo.title')} description={t('schedule.seo.description')} />
      <BreadCrumb
        list={[
          {
            title: 'TOP',
            link: '/',
          },
          {
            title: t('menu.match'),
          },
        ]}
        currentPage={t('schedule.matchSchedule')}
      />
      <BackGround>
        <section className={Styles.schedule}>
          <div className={CommonStyles.container}>
            <h1>{t('menu.match')}</h1>
            <div className={Styles.schedule__tab}>
              <p className={Styles.schedule__tab_color}>
                {t('schedule.matchSchedule')}
                <span className={Styles.schedule__triangle} />
              </p>
              <Link to="/match/">
                <p className={Styles.schedule__tab_grey}>{t('schedule.matchResult')}</p>
              </Link>
            </div>

            <div className={Styles.schedule__year}>
              <ul className={Styles.schedule__year__list}>
                {years
                  .slice()
                  .reverse()
                  .map((year) => (
                    <li>
                      <button
                        key={year}
                        className={age === year && Styles.schedule__current_age}
                        onClick={() => {
                          setAge(year);
                        }}
                      >
                        {year}
                      </button>
                    </li>
                  ))}
              </ul>
            </div>

            {eventItems[years.indexOf(age)] && (
              <ul className={Styles.month_list}>
                {eventItems[years.indexOf(age)].items.map(({ month, items }) => (
                  <li>
                    <h2>
                      <span>{month}</span>
                      <small>{dayjs(month).locale('en').format('MMM')}</small>
                    </h2>
                    <ul className={Styles.event_list}>
                      {items.map(({ node }) => (
                        <li key={node.eventId}>
                          <Link to={`/news/${contentPathHelper(node.eventId)}/`}>
                            <p className={Styles.event_list__item}>
                              {/* イベント種別ごとのアイコンを設定してください */}
                              {node.type[0] === EVENT_TYPE.shingle && <img src={Flag} className="flag" alt="flag" />}
                              {node.type[0] === EVENT_TYPE.exhibition && <img src={Cup} className="cup" alt="cup" />}
                              {node.type[0] === EVENT_TYPE.tournament && <img src={Cup} className="cup" alt="cup" />}
                              <time>{dayjs(node.in_date).format('D (dd)')}</time>
                              <span>
                                {node.title}
                              </span>
                            </p>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            )}
            <p className={Styles.schedule__bottom_text}>{t('schedule.comingSoon')}</p>
          </div>
        </section>
      </BackGround>
    </Layout>
  );
};

export const query = graphql`
  query {
  allMicrocmsEvent(
    filter: {is_private: {eq: false}}
    sort: {fields: date, order: DESC}
  ) {
    edges {
      node {
        eventId
        in_date
        title
        type
      }
    }
  }
}

`;

export default SchedulePage;
